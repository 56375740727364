<template>
  <div class="modify">
    <el-form
      :inline-message="true"
      :model="ruleForm"
      :rules="rules"
      label-width="110px"
      ref="ruleForm"
    >
      <p class="tips1">
        当前手机号：<span class="tips1__mobile">{{ ruleForm.mobile }}</span>
      </p>
      <p class="tips1">为了确认身份，我们需要验证您的安全手机</p>
      <p class="tips2">点击发送验证码按钮，将会有一条验证码短信发送至手机</p>
      <el-form-item label="支付密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" show-password></el-input>
      </el-form-item>
      <p class="modify-text">密码设置为6位数字</p>
      <el-form-item label="确认支付密码" prop="confirmPassword">
        <el-input type="password" v-model="ruleForm.confirmPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="手机验证码" prop="verifyCode">
        <el-input class="verifyCode" v-model="ruleForm.verifyCode"></el-input>
        <!-- <el-button :disabled="isClick" @click="getCode" class="modify__btn--code" type="text">{{
          tips
        }}</el-button> -->
        <getVerificationCode :mobile="ruleForm.mobile" class="modify__btn--code" />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="saveLoading"
          @click="submitForm('ruleForm')"
          class="modify__btn--save"
          type="primary"
          >确 定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
//import { getCode } from '@/api/system/user';
import { updatePayPassword } from '@/api/login';
import { mapActions } from 'vuex';
import { getAuthenticateInfo } from '@/api/step';
import md5 from '@/common/js-md5/md5';
import { RSAencryptedData, EncryptedParam } from '@/common/rsa';
import getVerificationCode from '@/components/getVerificationCode';
export default {
  name: 'modify',
  components: { getVerificationCode },
  data() {
    // 校验密码为数字
    const checkpassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'));
      }
      if (isNaN(Number(value))) {
        callback(new Error('请输入数字'));
      }
      if (value.toString().length !== 6) {
        callback(new Error('输入的密码长度需为6位数'));
      }
      callback();
    };
    const passwordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        mobile: '',
        password: '',
        confirmPassword: '',
        verifyCode: '',
      },
      rules: {
        password: [{ required: true, validator: checkpassword, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: passwordCheck, trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      tips: '获取验证码',
      isClick: false,
      saveLoading: false,
      beforeUrl: '',
    };
  },
  created() {
    this.getMobile();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path) {
        vm.beforeUrl = from.path;
      }
    });
  },
  methods: {
    ...mapActions(['GetAuthenticateInfo']),
    getMobile() {
      getAuthenticateInfo().then(res => {
        this.ruleForm.mobile = res.data.mobile;
      });
    },
    submitForm(formName) {
      const md5Password = md5(this.ruleForm.password); // md5加密
      const timestamp = Date.now(); // 时间戳
      const postData = {
        payPassword: RSAencryptedData(md5Password), // rsa加密
        verifyCode: this.ruleForm.verifyCode,
      };
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveLoading = true;
          updatePayPassword(postData, EncryptedParam(postData, timestamp), timestamp)
            .then(res => {
              if (res.code === '0') {
                if (this.beforeUrl === '/syoung/pay/list') {
                  this.$router.go(-1);
                  return;
                }
                this.$router.push('/syoung/shop/detail');
              }
            })
            .finally(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // getCode() {
    //   if (!this.ruleForm.mobile || !/^1\d{10}$/.test(this.ruleForm.mobile)) {
    //     this.$message.error('请正确输入手机号');
    //     return;
    //   }
    //   this.isClick = true;
    //   let sec = 59;
    //   this.t = setInterval(() => {
    //     this.tips = `${sec}s`;
    //     if (!sec || sec < 1) {
    //       clearInterval(this.t);
    //       this.tips = '获取验证码';
    //       this.isClick = false;
    //     }
    //     sec--;
    //   }, 1000);
    //   getCode(this.ruleForm.mobile).then(response => {
    //     this.$message.success('发送成功');
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.tips1 {
  text-align: center;
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
  margin-left: -150px;
  &__mobile {
    font-size: 22px;
  }
}
.tips2 {
  margin-left: -150px;
  text-align: center;
  font-size: 18px;
  color: #9d9d9d;
  margin-bottom: 30px;
}
.modify {
  padding-top: 136px;
  &-text {
    padding-left: 110px;
    margin-bottom: 10px;
  }
  .el-form {
    width: 648px;
    margin: 0 auto;
    .el-input {
      width: 350px;
    }
    .modify-btn-save {
      width: 250px;
      margin-left: 100px;
    }
  }

  &__btn {
    &--code {
      color: #9d9d9d;
      font-size: 18px;
      position: absolute;
      right: 208px;
      top: 0;
      width: 100px;
      height: 36px;
      line-height: 36px;
      padding: 0;
      text-align: center;
    }
    &--save {
      background: #AB0033;
      border-radius: 5px;
      font-size: 18px;
      border-color: #AB0033;
      width: 250px;
      color: #fff;
      margin: 30px 0 0 50px;
    }
  }
}
</style>